import { defineSemanticTokens, defineTokens } from '@chakra-ui/react';

export const tokens = defineTokens({
  colors: {
    white: {
      value: '#ffffff'
    },
    black: {
      value: '#000000'
    },
    blackAlpha: {
      50: { value: 'rgba(0, 0, 0, 0.04)' },
      100: { value: 'rgba(0, 0, 0, 0.06)' },
      200: { value: 'rgba(0, 0, 0, 0.08)' },
      300: { value: 'rgba(0, 0, 0, 0.16)' },
      400: { value: 'rgba(0, 0, 0, 0.24)' },
      500: { value: 'rgba(0, 0, 0, 0.36)' },
      600: { value: 'rgba(0, 0, 0, 0.48)' },
      700: { value: 'rgba(0, 0, 0, 0.64)' },
      800: { value: 'rgba(0, 0, 0, 0.80)' },
      900: { value: 'rgba(0, 0, 0, 0.92)' },
      950: { value: 'rgba(0, 0, 0, 0.95)' }
    },
    grey: {
      950: { value: '#424242' },
      900: { value: '#929088' },
      700: { value: '#B3B2AC' },
      500: { value: '#D4D3D0' },
      300: { value: '#DFDEDC' },
      100: { value: '#EAE9E8' },
      50: { value: '#F4F4F1' }
    },
    blue: {
      900: { value: '#51A0DD' },
      100: { value: '#D0E7F6' }
    },
    red: {
      900: { value: '#B13024' },
      700: { value: '#F7A7A3' },
      500: { value: '#F9BCB9' },
      300: { value: '#FBD2D0' },
      100: { value: '#FDE9E8' }
    },
    yellow: {
      900: { value: '#BD7305' },
      700: { value: '#FFC62F' },
      500: { value: '#FFD461' },
      300: { value: '#FFE294' },
      100: { value: '#FFF0C7' }
    },
    green: {
      900: { value: '#008054' },
      700: { value: '#19B459' },
      500: { value: '#86D49A' }
    },
    accent: {
      grey: {
        900: { value: '#2A2C3C' },
        700: { value: '#515677' }
      },
      purple: {
        900: { value: '#3D3567' },
        700: { value: '#735CDC' }
      },
      blue: {
        900: { value: '#2239A2' },
        700: { value: '#3B78DD' }
      },
      green: {
        900: { value: '#2F6747' },
        700: { value: '#56B288' }
      },
      orange: {
        900: { value: '#745447' },
        700: { value: '#E59635' }
      },
      red: {
        900: { value: '#6E221F' },
        700: { value: '#D64D5D' }
      }
    }
  },
  fonts: {
    heading: {
      value:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    body: {
      value:
        'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    mono: {
      value:
        'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace'
    }
  },
  fontSizes: {
    '2xs': {
      value: '1.1rem'
    },
    xs: {
      value: '1.2rem'
    },
    sm: {
      value: '1.30rem'
    },
    md: {
      value: '1.40rem'
    },
    lg: {
      value: '1.70rem'
    },
    xl: {
      value: '2.20rem'
    },
    '2xl': {
      value: '2.70rem'
    },
    '3xl': {
      value: '3.00rem'
    },
    '4xl': {
      value: '3.60rem'
    },
    '5xl': {
      value: '4.80rem'
    },
    '6xl': {
      value: '6.00rem'
    },
    '7xl': {
      value: '7.20rem'
    },
    '8xl': {
      value: '9.60rem'
    },
    '9xl': {
      value: '12.80rem'
    }
  },
  fontWeights: {
    hairline: {
      value: 100
    },
    thin: {
      value: 200
    },
    light: {
      value: 300
    },
    normal: {
      value: 400
    },
    medium: {
      value: 600
    },
    semibold: {
      value: 600
    },
    bold: {
      value: 700
    },
    extrabold: {
      value: 800
    },
    black: {
      value: 900
    }
  },
  radii: {
    '2xs': {
      value: '0.10rem'
    },
    xs: {
      value: '0.20rem'
    },
    sm: {
      value: '0.40rem'
    },
    md: {
      value: '0.60rem'
    },
    lg: {
      value: '0.80rem'
    },
    xl: {
      value: '1.20rem'
    },
    '2xl': {
      value: '1.60rem'
    },
    '3xl': {
      value: '2.40rem'
    },
    '4xl': {
      value: '3.20rem'
    },
    full: {
      value: '9999px'
    }
  },
  spacing: {
    '1': {
      value: '0.40rem'
    },
    '2': {
      value: '0.80rem'
    },
    '3': {
      value: '1.20rem'
    },
    '4': {
      value: '1.60rem'
    },
    '5': {
      value: '2.00rem'
    },
    '6': {
      value: '2.40rem'
    },
    '7': {
      value: '2.80rem'
    },
    '8': {
      value: '3.20rem'
    },
    '9': {
      value: '3.60rem'
    },
    '10': {
      value: '4.00rem'
    },
    '12': {
      value: '4.80rem'
    },
    '14': {
      value: '5.60rem'
    },
    '16': {
      value: '6.40rem'
    },
    '20': {
      value: '8.00rem'
    },
    '24': {
      value: '9.60rem'
    },
    '28': {
      value: '11.20rem'
    },
    '32': {
      value: '12.80rem'
    },
    '36': {
      value: '14.40rem'
    },
    '40': {
      value: '16.00rem'
    },
    '44': {
      value: '17.60rem'
    },
    '48': {
      value: '19.20rem'
    },
    '52': {
      value: '20.80rem'
    },
    '56': {
      value: '22.40rem'
    },
    '60': {
      value: '24.00rem'
    },
    '64': {
      value: '25.60rem'
    },
    '72': {
      value: '28.80rem'
    },
    '80': {
      value: '32.00rem'
    },
    '96': {
      value: '38.40rem'
    },
    '0.5': {
      value: '0.20rem'
    },
    '1.5': {
      value: '0.60rem'
    },
    '2.5': {
      value: '1.00rem'
    },
    '3.5': {
      value: '1.40rem'
    }
  },
  sizes: {
    '1': {
      value: '0.40rem'
    },
    '2': {
      value: '0.80rem'
    },
    '3': {
      value: '1.20rem'
    },
    '4': {
      value: '1.60rem'
    },
    '5': {
      value: '2.00rem'
    },
    '6': {
      value: '2.40rem'
    },
    '7': {
      value: '2.80rem'
    },
    '8': {
      value: '3.20rem'
    },
    '9': {
      value: '3.60rem'
    },
    '10': {
      value: '4.00rem'
    },
    '12': {
      value: '4.80rem'
    },
    '14': {
      value: '5.60rem'
    },
    '16': {
      value: '6.40rem'
    },
    '20': {
      value: '8.00rem'
    },
    '24': {
      value: '9.60rem'
    },
    '28': {
      value: '11.20rem'
    },
    '32': {
      value: '12.80rem'
    },
    '36': {
      value: '14.40rem'
    },
    '40': {
      value: '16.00rem'
    },
    '44': {
      value: '17.60rem'
    },
    '48': {
      value: '19.20rem'
    },
    '52': {
      value: '20.80rem'
    },
    '56': {
      value: '22.40rem'
    },
    '60': {
      value: '24.00rem'
    },
    '64': {
      value: '25.60rem'
    },
    '72': {
      value: '28.80rem'
    },
    '80': {
      value: '32.00rem'
    },
    '96': {
      value: '38.40rem'
    },
    '3xs': {
      value: '22.40rem'
    },
    '2xs': {
      value: '25.60rem'
    },
    xs: {
      value: '32.00rem'
    },
    sm: {
      value: '38.40rem'
    },
    md: {
      value: '44.80rem'
    },
    lg: {
      value: '51.20rem'
    },
    xl: {
      value: '57.60rem'
    },
    '2xl': {
      value: '67.20rem'
    },
    '3xl': {
      value: '76.80rem'
    },
    '4xl': {
      value: '89.60rem'
    },
    '5xl': {
      value: '102.40rem'
    },
    '6xl': {
      value: '115.20rem'
    },
    '7xl': {
      value: '128.00rem'
    },
    '8xl': {
      value: '144.00rem'
    },
    '0.5': {
      value: '0.20rem'
    },
    '1.5': {
      value: '0.60rem'
    },
    '2.5': {
      value: '1.00rem'
    },
    '3.5': {
      value: '1.40rem'
    },
    '1/2': {
      value: '50%'
    },
    '1/3': {
      value: '33.333333%'
    },
    '2/3': {
      value: '66.666667%'
    },
    '1/4': {
      value: '25%'
    },
    '3/4': {
      value: '75%'
    },
    '1/5': {
      value: '20%'
    },
    '2/5': {
      value: '40%'
    },
    '3/5': {
      value: '60%'
    },
    '4/5': {
      value: '80%'
    },
    '1/6': {
      value: '16.666667%'
    },
    '2/6': {
      value: '33.333333%'
    },
    '3/6': {
      value: '50%'
    },
    '4/6': {
      value: '66.666667%'
    },
    '5/6': {
      value: '83.333333%'
    },
    '1/12': {
      value: '8.333333%'
    },
    '2/12': {
      value: '16.666667%'
    },
    '3/12': {
      value: '25%'
    },
    '4/12': {
      value: '33.333333%'
    },
    '5/12': {
      value: '41.666667%'
    },
    '6/12': {
      value: '50%'
    },
    '7/12': {
      value: '58.333333%'
    },
    '8/12': {
      value: '66.666667%'
    },
    '9/12': {
      value: '75%'
    },
    '10/12': {
      value: '83.333333%'
    },
    '11/12': {
      value: '91.666667%'
    },
    max: {
      value: 'max-content'
    },
    min: {
      value: 'min-content'
    },
    fit: {
      value: 'fit-content'
    },
    prose: {
      value: '60ch'
    },
    full: {
      value: '100%'
    },
    dvh: {
      value: '100dvh'
    },
    svh: {
      value: '100svh'
    },
    lvh: {
      value: '100lvh'
    },
    dvw: {
      value: '100dvw'
    },
    svw: {
      value: '100svw'
    },
    lvw: {
      value: '100lvw'
    },
    vw: {
      value: '100vw'
    },
    vh: {
      value: '100vh'
    },
    cursors: {
      button: {
        value: 'pointer'
      },
      checkbox: {
        value: 'default'
      },
      disabled: {
        value: 'not-allowed'
      },
      menuitem: {
        value: 'pointer'
      },
      option: {
        value: 'default'
      },
      radio: {
        value: 'default'
      },
      slider: {
        value: 'default'
      },
      switch: {
        value: 'pointer'
      }
    }
  }
});

export const semanticTokens = defineSemanticTokens({
  shadows: {
    xs: {
      value: '0px 1px 4px {colors.blackAlpha.200}'
    },
    sm: {
      value: '0px 2px 6px {colors.blackAlpha.200}'
    },
    md: {
      value: '0px 3px 12px {colors.blackAlpha.200}'
    },
    lg: {
      value: '0px 8px 16px {colors.blackAlpha.200}'
    },
    xl: {
      value: '0px 16px 30px {colors.blackAlpha.400}'
    },
    '2xl': {
      value: '0px 24px 46px {colors.blackAlpha.400}'
    }
  }
});
