/* eslint-disable max-lines */

import { createSystem, defaultConfig, defineConfig } from '@chakra-ui/react';
import _ from 'lodash';
import { buttonRecipe } from 'components/chakra/button/design-system/button.recipe';
import { semanticTokens, tokens } from 'src/lib/chakra/theme/shell/tokens';
import { textStyles } from 'src/lib/chakra/theme/shell/text-styles';
import { menuSlotRecipe } from 'components/chakra/menu/design-system/menu.recipe';
import { drawerSlotRecipe } from 'components/chakra/drawer/design-system/drawer.recipe';
import { tabsSlotRecipe } from 'components/chakra/tabs/design-system/tabs.recipe';

export const shellChakraConfig = defineConfig({
  ...defaultConfig,
  preflight: {
    scope: '.chakra-root',
    level: 'parent'
  },
  theme: {
    ...defaultConfig.theme,
    recipes: {
      ...defaultConfig.theme?.recipes,
      button: buttonRecipe
    },
    slotRecipes: {
      ...defaultConfig.theme?.slotRecipes,
      menu: menuSlotRecipe,
      drawer: drawerSlotRecipe,
      tabs: tabsSlotRecipe
    },
    tokens: {
      ...defaultConfig.theme?.tokens,
      ...tokens
    },
    semanticTokens: {
      ...defaultConfig.theme?.semanticTokens,
      ...semanticTokens
    },
    textStyles
  }
});

/**
 * Processes Chakra theme tokens to scale rem values up or down
 * Isn't intended to be used at runtime, dev-mode utility only.
 * @param tokenKeys
 * @param multiplier
 */
function scaleRems(
  tokenKeys = ['radii', 'spacing', 'sizes', 'fontSizes'],
  multiplier = 1.6
) {
  const theme = { ...defaultConfig.theme };

  for (const tokenKey of tokenKeys) {
    if (theme.tokens) {
      theme.tokens[tokenKey] = _.mapValues(theme.tokens?.[tokenKey], (v) => {
        return {
          ...v,
          value: v.value.includes('rem')
            ? (v.value.split('rem')[0] * multiplier).toFixed(2) + 'rem'
            : v.value
        };
      });
    }
  }

  return theme;
}

export const shellChakraSystem = createSystem(shellChakraConfig);
export default shellChakraSystem;
